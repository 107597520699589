@use 'color-themes';
@use 'variables';

.p-dropdown {
    .p-dropdown-toggle,
    span.close-ico {
        cursor: pointer;
    }
    span.close-ico {
        color: #bf9000;
    }
    span.close-ico:hover {
        color: #0056b3;
    }
    .p-dropdown-wrapper {
        position: relative;
        .p-dropdown-arrow {
            position: absolute;
            z-index: 100;
            top: -10px;
            right: 25px;
            display: block;
            height: 0;
            width: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            @include color-themes.themed() {
                border-bottom: 11px solid color-themes.get-theme-color('bg-color-lighter');
            }
        }
        .p-dropdown-inner {
            // transition: max-height 1s linear; // now possible to actually animate if wanted
            padding: 0px;
            overflow: hidden;
            display: block;
            max-height: 0;
            @include color-themes.themed() {
                background-color: color-themes.get-theme-color('bg-color-lighter');
            }
            @media screen and (max-width: 500px) {
                position: fixed;
                padding: 10px 5px;
                top: 0px;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1000;
                overflow: scroll;
            }
            @media screen and (min-width: 501px) {
                position: absolute;
                padding: 0px;
                right: -20px;
                z-index: 99;
                top: 10px;
                min-width: 500px;
                border-radius: 6px;
                box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
            }
            .p-dropdown-inner-container {
                label {
                    text-align: right;
                }
                button {
                    width: 220px;
                }
                .form-group {
                    margin-bottom: 10px;
                }
            }
        }
        .la-remove {
            font-size: 2em;
        }
        .flaticon-refresh {
            font-size: 2em;
            padding-right: 0.5em;
        }
    }
    &.p-dropdown--open .p-dropdown-inner {
        display: block;
        max-height: 800px;
        overflow: visible;
        @media screen and (max-width: 500px) {
            padding: 10px 5px;
        }
        @media screen and (min-width: 501px) {
            padding: 20px;
        }
    }
    // &.p-dropdown--open .p-dropdown-arrow {
    //     display: block;
    // }
}
dropdown.small .p-dropdown .p-dropdown-wrapper .p-dropdown-inner {
    @media screen and (min-width: 501px) {
        min-width: 325px;
        max-width: 435px;
    }
}
dropdown.no-padding .p-dropdown .p-dropdown-wrapper .p-dropdown-inner {
    @media screen and (max-width: 500px) {
        padding: 0;
    }
    @media screen and (min-width: 501px) {
        padding: 0;
    }
}
