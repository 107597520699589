
$white: #fff;
$black: rgb(21, 21, 33);
$dark-lighter: #1e1e2d;

$app-primary: #7e94ac;
$app-primary-lighter: #d8dfe6;
$app-primary-darker: hsl(213, 40%, 58%);


$app-accent: #bf9000;
$app-accent-lighter: #ecdeb3;
$app-accent-darker: #a97300;

$app-warn: #ff0000;
$app-warn-lighter: #ffb3b3;
$app-warn-darker: #ff0000;

$white-smoke-100: #fcfcfc;
$white-smoke-200: #f9f9f9;
$white-smoke-300: #f5f5f5;
$white-smoke-400: #f2f2f2;
$white-smoke: #efefef;
$white-smoke-600: #bfbfbf;
$white-smoke-700: #8f8f8f;
$white-smoke-800: #606060;
$white-smoke-900: #303030;

$light-gray-100: #f6f6f6;
$light-gray-200: #ededed;
$light-gray-300: #e5e5e5;
$light-gray-400: #dcdcdc;
$light-gray: #d3d3d3;
$light-gray-600: #a9a9a9;
$light-gray-700: #7f7f7f;
$light-gray-800: #545454;
$light-gray-900: #2a2a2a;

$dark-gray: #575962;


//Light mode
$text-color-light: #575962;
$bg-color-light: $white;
$text-red-light: #ff0000;
$text-green-light: #008000;
$primary-light: #7e94ac;


// dark mode
$text-color-dark: #B9B9BD;
$bg-color-dark: #151521;
$text-green-dark: #50cd89;
$text-red-dark: #f1416c;

