<div *ngIf="header" class="dashboard-header" id="sub-header">
    <div class="row m-row--no-padding">
        <div class="col-8">
            <h3
                class="dashboard-header-title"
                [ngStyle]="{
                    'margin-top': headerTitleMargin && !isInSlide ? '32px' : '0px'
                }"
            >
                {{ dashboardService.title }}
                <span
                    *ngIf="
                        entityLabel &&
                        dashboardService.title !== 'Home' &&
                        dashboardService.title !== 'Docs' &&
                        dashboardService.title !== 'Radisson Academy'
                    "
                    [hidden]="
                        !entityService.selectedEntityLabel &&
                        dashboardService.title !== 'Home'
                    "
                    class="entity-label d-none d-md-inline"
                    id="dashboard_entity_label"
                >
                    {{ entityService.selectedEntityLabel }}
                </span>
                <span
                    *ngIf="
                        dashboardService.options_title &&
                        dashboardService.options_title.length
                    "
                    class="entity-label d-none d-xl-inline"
                >
                    | {{ dashboardService.options_title }}
                </span>
            </h3>
        </div>
        <div class="col-4">
            <div
                class="p-table"
                *ngIf="showLegacyOptionMenu"
                [ngClass]="{
                    'sticky-options-filter': stickyMenus,
                    'sticky-options-filter-slide': isInSlide
                }"
            >
                <div *ngIf="datePicker"></div>
                <div *ngIf="showDataControls">
                    <app-dashboard-data-controls
                        #datacontrolDropdown
                        [outsideCloseDisabled]="dropdownOutsideCloseDisabled"
                        (progressDataUpdateEvent)="updateProgressData($event)"
                    >
                        <div class="button options-button">
                            <button
                                class="round-button"
                                ngbTooltip="Data Sources"
                                placement="bottom"
                            >
                                <i class="la la-info"></i>
                            </button>
                        </div>
                        <div class="dropdown-content text-left">
                            <h4>Data Controls</h4>
                            <table id="data-src" (click)="callServer()">
                                <th>Status</th>
                                <th>Data Source</th>
                                <th>Progress</th>
                                <tr *ngFor="let row of progressData">
                                    <td
                                        class="status"
                                        *ngIf="row.progress < 1; else elseBlock"
                                    >
                                        <i
                                            class="fa fa-times-circle d-table-cell t-c text-danger"
                                            style="display: inline"
                                        ></i>
                                    </td>
                                    <ng-template class="status" #elseBlock
                                        ><i
                                            class="fa fa-check-circle d-table-cell t-c pt-2 text-success"
                                        ></i
                                    ></ng-template>
                                    <td>
                                        {{ row.description }}
                                    </td>
                                    <td>
                                        <progressbar
                                            class="customClass"
                                            id="bar"
                                            [max]="1"
                                            [value]="row.progress"
                                        >
                                            {{ row.progress * 100 }} %
                                        </progressbar>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </app-dashboard-data-controls>
                </div>

                <div *ngIf="showOptions && options && options.length">
                    <app-dashboard-options
                        #optionsDropdown
                        [outsideCloseDisabled]="dropdownOutsideCloseDisabled"
                    >
                        <div class="button options-button">
                            <button
                                class="round-button"
                                ngbTooltip="Options"
                                placement="bottom"
                            >
                                <i class="la la-sliders"></i>
                            </button>
                        </div>
                        <div class="dropdown-content">
                            <form
                                *ngIf="!hiddenOptionsContent"
                                [ngClass]="{
                                    'one-option-column': !options[0].totalColumns,
                                    'two-option-column': options[0].totalColumns == 2,
                                    'three-option-column': options[0].totalColumns == 3,
                                    'four-option-column': options[0].totalColumns == 4
                                }"
                            >
                                <div class="form-group">
                                    <h4>Options</h4>
                                    {{ validationList | json }}
                                </div>
                                <ng-container *ngFor="let opti of options">
                                    <div
                                        *ngIf="opti.firstInColumn"
                                        [ngClass]="{
                                            '': !opti.column,
                                            'option-column-one': opti.column == 1,
                                            'option-column-two': opti.column == 2,
                                            'option-column-three': opti.column == 3,
                                            'option-column-four': opti.column == 4
                                        }"
                                        [ngStyle]="{
                                            width:
                                                options[0].totalColumns === 1
                                                    ? 'auto'
                                                    : opti.column ===
                                                        options[0].totalColumns
                                                      ? '400px'
                                                      : '340px',
                                            'padding-right':
                                                options[0].totalColumns === 1
                                                    ? ''
                                                    : opti.column ===
                                                        options[0].totalColumns
                                                      ? ''
                                                      : '20px',
                                            'border-right':
                                                options[0].totalColumns === 1
                                                    ? ''
                                                    : opti.column ===
                                                        options[0].totalColumns
                                                      ? ''
                                                      : '1px gray dashed'
                                        }"
                                    >
                                        <ng-container
                                            *ngFor="let option of options; let i = index"
                                        >
                                            <div
                                                *ngIf="option.column === opti.column"
                                                class="form-group row g-0"
                                            >
                                                <ng-container *ngIf="!option.invisible">
                                                    <label
                                                        for="exampleSelect1"
                                                        class="col-sm-4 col-form-label"
                                                        style="padding-right: 10px"
                                                    >
                                                        <span
                                                            *ngIf="option.tooltip"
                                                            placement="left"
                                                            [ngbTooltip]="option.tooltip"
                                                            style="margin-right: 3px"
                                                        >
                                                            <i
                                                                class="fa flaticon-question-circle-o"
                                                                style="font-size: 16px"
                                                            ></i>
                                                        </span>
                                                        {{ option.title }}
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <!-- TEXT INPUT -->
                                                        <div
                                                            *ngIf="
                                                                option.type ==
                                                                'TEXT-INPUT'
                                                            "
                                                            class="input-group"
                                                        >
                                                            <input
                                                                name="{{ option.name }}"
                                                                type="text"
                                                                [disabled]="
                                                                    !dashboardService.isAllowed
                                                                "
                                                                class="form-control"
                                                                [(ngModel)]="
                                                                    option.selected
                                                                "
                                                            />
                                                        </div>
                                                        <!-- NUMERIC INPUT -->
                                                        <div
                                                            *ngIf="
                                                                option.type ==
                                                                'NUMERIC-INPUT'
                                                            "
                                                            class="input-group"
                                                        >
                                                            <input
                                                                name="{{ option.name }}"
                                                                type="text"
                                                                numbers-only
                                                                [disabled]="
                                                                    !dashboardService.isAllowed
                                                                "
                                                                class="form-control"
                                                                [(ngModel)]="
                                                                    option.selected
                                                                "
                                                            />
                                                        </div>
                                                        <!-- SELECT Currency with search -->
                                                        <!-- <mat-form-field appearance="outline"  *ngIf="option.type == 'SELECT'" style="display: block; width: 220px">
                                                            <mat-select [(value)]="option.selected">
                                                                <mat-option *ngFor="let opt of option.options" [value]="opt.id" (click)="$event.stopPropagation()">
                                                                    {{opt.label}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field> -->
                                                        <!-- SELECT  -->
                                                        <select
                                                            *ngIf="
                                                                option.type == 'SELECT' &&
                                                                option.name !== 'currency'
                                                            "
                                                            name="{{ option.name }}"
                                                            class="selectpicker"
                                                            [(ngModel)]="option.selected"
                                                        >
                                                            <option
                                                                *ngFor="
                                                                    let opt of option.options
                                                                "
                                                                [ngValue]="opt.id"
                                                            >
                                                                {{ opt.label }}
                                                            </option>
                                                        </select>
                                                        <div
                                                            *ngIf="
                                                                option.type ===
                                                                    'SELECT' &&
                                                                option.name === 'currency'
                                                            "
                                                            class="dropdown-search"
                                                        >
                                                            <div
                                                                class="btn-group"
                                                                role="group"
                                                            >
                                                                <div
                                                                    class="btn-group"
                                                                    role="group"
                                                                    ngbDropdown
                                                                >
                                                                    <button
                                                                        mat-button
                                                                        class="btn btn-secondary btn-dropdown"
                                                                        style="
                                                                            border: 1px
                                                                                solid
                                                                                #7e94ac;
                                                                            color: #7e94ac;
                                                                            padding-left: 10px;
                                                                            padding-right: 10px;
                                                                            height: 39px;
                                                                            font-size: 15px;
                                                                            min-width: 80px;
                                                                        "
                                                                        ngbDropdownToggle
                                                                    >
                                                                        {{
                                                                            currency
                                                                                .options[
                                                                                currency
                                                                                    .localCurrency
                                                                            ].label ||
                                                                                'Currency'
                                                                        }}
                                                                    </button>

                                                                    <div
                                                                        ngbDropdownMenu
                                                                        class="dropdown-menu dropdown-container"
                                                                    >
                                                                        <div
                                                                            class="dropdown-search"
                                                                        >
                                                                            <input
                                                                                [ngModelOptions]="{
                                                                                    standalone: true
                                                                                }"
                                                                                type="text"
                                                                                class="form-control"
                                                                                placeholder="Search..."
                                                                                [(ngModel)]="
                                                                                    searchTerm
                                                                                "
                                                                            />
                                                                        </div>

                                                                        <div
                                                                            style="
                                                                                max-height: 25vh;
                                                                                overflow-y: auto;
                                                                            "
                                                                            class="scrollable-dropdown"
                                                                        >
                                                                            <button
                                                                                class="dropdown-item"
                                                                                *ngFor="
                                                                                    let item of filteredOptions()
                                                                                "
                                                                                (click)="
                                                                                    select(
                                                                                        item
                                                                                    )
                                                                                "
                                                                            >
                                                                                {{
                                                                                    item.label
                                                                                }}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- MULTIPLE-SELECT -->
                                                        <div
                                                            class="dx-field selectpicker"
                                                            *ngIf="
                                                                option.type ==
                                                                'MULTIPLE-SELECT'
                                                            "
                                                        >
                                                            <div class="dx-field-value">
                                                                <dx-drop-down-box
                                                                    [(value)]="
                                                                        option.selected
                                                                    "
                                                                    valueExpr="id"
                                                                    [disabled]="
                                                                        !dashboardService.isAllowed
                                                                    "
                                                                    displayExpr="label"
                                                                    placeholder="Select a value..."
                                                                    [showClearButton]="
                                                                        false
                                                                    "
                                                                    [dataSource]="
                                                                        option.options
                                                                    "
                                                                    name="{{
                                                                        option.name
                                                                    }}"
                                                                    (onOpened)="
                                                                        updateDropdownOutsideCloseTrigger(
                                                                            true
                                                                        )
                                                                    "
                                                                    (onClosed)="
                                                                        updateDropdownOutsideCloseTrigger(
                                                                            false
                                                                        )
                                                                    "
                                                                >
                                                                    <div
                                                                        *dxTemplate="
                                                                            let data of 'content'
                                                                        "
                                                                    >
                                                                        <dx-list
                                                                            keyExpr="id"
                                                                            [dataSource]="
                                                                                option.options
                                                                            "
                                                                            [selectionMode]="
                                                                                multiple
                                                                            "
                                                                            [selectAllMode]="
                                                                                allPages
                                                                            "
                                                                            [showSelectionControls]="
                                                                                true
                                                                            "
                                                                            [selectedItemKeys]="
                                                                                option.selected
                                                                            "
                                                                            (onSelectionChanged)="
                                                                                onSelectionChanged(
                                                                                    $event,
                                                                                    i
                                                                                )
                                                                            "
                                                                            itemTemplate="listItem"
                                                                        >
                                                                            <div
                                                                                *dxTemplate="
                                                                                    let itemData of 'listItem'
                                                                                "
                                                                            >
                                                                                {{
                                                                                    option.dataType ==
                                                                                    'date'
                                                                                        ? formatMyDate(
                                                                                              itemData.label,
                                                                                              'dd-MMM-yy',
                                                                                              'en-US'
                                                                                          )
                                                                                        : itemData.label
                                                                                }}
                                                                            </div>
                                                                        </dx-list>
                                                                    </div>
                                                                </dx-drop-down-box>

                                                                <div
                                                                    class="text-danger"
                                                                    *ngIf="
                                                                        option.mandatory &&
                                                                        option.selected
                                                                            .length == 0
                                                                    "
                                                                >
                                                                    {{ option.title }} is
                                                                    required!
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- MULTIPLE-SELECT-TREE -->

                                                        <div
                                                            class="dx-field selectpicker"
                                                            *ngIf="
                                                                option.type ==
                                                                'MULTIPLE-SELECT-TREE'
                                                            "
                                                        >
                                                            <div>
                                                                <dx-tree-list
                                                                    #dxComponents
                                                                    [elementAttr]="{
                                                                        id: option.name
                                                                    }"
                                                                    [dataSource]="
                                                                        option.options
                                                                    "
                                                                    [height]="250"
                                                                    keyExpr="id"
                                                                    parentIdExpr="parent"
                                                                    [showRowLines]="false"
                                                                    [showBorders]="false"
                                                                    [columnAutoWidth]="
                                                                        true
                                                                    "
                                                                    [showColumnHeaders]="
                                                                        !option.noSelectAll
                                                                    "
                                                                    [(selectedRowKeys)]="
                                                                        option.selected
                                                                    "
                                                                    (onSelectionChanged)="
                                                                        onSelectionChangedTree(
                                                                            $event,
                                                                            i
                                                                        )
                                                                    "
                                                                    [scrolling]="{
                                                                        useNative: true
                                                                    }"
                                                                    style="width: 220px"
                                                                    [disabled]="
                                                                        !dashboardService.isAllowed
                                                                    "
                                                                >
                                                                    <dxo-selection
                                                                        mode="multiple"
                                                                        [recursive]="true"
                                                                        [allowSelectAll]="
                                                                            true
                                                                        "
                                                                    ></dxo-selection>
                                                                    <dxi-column
                                                                        dataField="label"
                                                                        [allowSorting]="
                                                                            false
                                                                        "
                                                                        caption="Select All"
                                                                    ></dxi-column>
                                                                </dx-tree-list>
                                                                <div
                                                                    class="text-danger"
                                                                    *ngIf="
                                                                        option.mandatory &&
                                                                        option.selected
                                                                            .length == 0
                                                                    "
                                                                >
                                                                    {{ option.title }} is
                                                                    required!
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- RADIO -->
                                                        <div
                                                            *ngIf="option.type == 'RADIO'"
                                                            name="{{ option.name }}"
                                                        >
                                                            <label
                                                                *ngFor="
                                                                    let opt of option.options
                                                                "
                                                                class="radio"
                                                                style="
                                                                    padding-top: calc(
                                                                        0.375rem + 1px
                                                                    );
                                                                    cursor: pointer;
                                                                "
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    class="options-radio"
                                                                    [disabled]="
                                                                        !dashboardService.isAllowed
                                                                    "
                                                                    name="{{
                                                                        option.name
                                                                    }}"
                                                                    [(ngModel)]="
                                                                        option.selected
                                                                    "
                                                                    [value]="opt.id"
                                                                />
                                                                <span>
                                                                    {{ opt.label }}</span
                                                                >
                                                            </label>
                                                        </div>
                                                        <!-- CHECKBOX -->
                                                        <div
                                                            *ngIf="
                                                                option.type == 'CHECKBOX'
                                                            "
                                                            name="{{ option.name }}"
                                                        >
                                                            <label
                                                                *ngFor="
                                                                    let opt of option.options
                                                                "
                                                                class="radio"
                                                                style="
                                                                    padding-top: calc(
                                                                        0.375rem + 1px
                                                                    );
                                                                    cursor: pointer;
                                                                "
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="{{
                                                                        option.name
                                                                    }}"
                                                                    [disabled]="
                                                                        !dashboardService.isAllowed
                                                                    "
                                                                    [checked]="
                                                                        option.selected.includes(
                                                                            opt.id
                                                                        )
                                                                    "
                                                                    (change)="
                                                                        onCheckboxOptionChnaged(
                                                                            i,
                                                                            opt.id
                                                                        )
                                                                    "
                                                                    style="
                                                                        filter: hue-rotate(
                                                                                -168deg
                                                                            )
                                                                            brightness(
                                                                                1.3
                                                                            );
                                                                    "
                                                                />
                                                                <span>
                                                                    {{ opt.label }}
                                                                </span>
                                                            </label>
                                                            <div
                                                                class="text-danger"
                                                                *ngIf="
                                                                    option.mandatory &&
                                                                    option.selected
                                                                        .length == 0
                                                                "
                                                            >
                                                                {{ option.title }} is
                                                                required!
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div
                                    class="form-group row g-0"
                                    [ngClass]="{
                                        '': options[0].totalColumns == 1,
                                        'option-column-two': options[0].totalColumns == 2,
                                        'option-column-three':
                                            options[0].totalColumns == 3,
                                        'option-column-four': options[0].totalColumns == 4
                                    }"
                                >
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-8">
                                        <button
                                            type="button"
                                            [disabled]="
                                                !dashboardService.isAllowed ||
                                                multiTreeDisableUpdateButton ||
                                                multiDisableUpdateButton ||
                                                checkboxDisableUpdateButton
                                            "
                                            (click)="updateOptions()"
                                            class="btn btn-primary"
                                            style="width: auto"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </app-dashboard-options>
                </div>
                <div *ngIf="downloadData">
                    <button class="round-button" (click)="download()">
                        <i class="fa fa-download"></i>
                    </button>
                </div>
                <div *ngIf="getPrintData">
                    <button
                        class="round-button"
                        (click)="print()"
                        ngbTooltip="Print to PDF"
                        placement="bottom"
                    >
                        <i class="fa fa-print"></i>
                    </button>
                </div>
                <div *ngIf="updateOrma">
                    <button
                        class="round-button"
                        (click)="uploadOrma()"
                        ngbTooltip="Upload Changes To Web Planning"
                        placement="bottom"
                    >
                        <i class="fa fa-upload"></i>
                    </button>
                </div>
                <div *ngIf="getHtmlData">
                    <button
                        class="round-button"
                        (click)="sendToWkHtmlToPdf()"
                        ngbTooltip="Print to PDF"
                        placement="bottom"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                    </button>
                </div>
                <div *ngIf="showSlide">
                    <button
                        class="round-button"
                        (click)="changeSliderPageView()"
                        ngbTooltip="Slideshow mode"
                        placement="bottom"
                    >
                        <i class="la la-arrows"></i>
                    </button>
                </div>
            </div>
            <div
                *ngIf="!showLegacyOptionMenu"
                style="
                    display: flex;
                    justify-content: end;
                    height: 100%;
                    align-items: center;
                    gap: 10px;
                "
            >
                <toolbar-dropdown-nav-year *ngIf="showYearSelector"
                    [options]="getYearOptions() | async"
                    [selectedOption]="getSelectedYear() | async"
                    [yearId]="dashboardService.dashboardYearId"
                    (valueChange)="selectYear($event)"
                >
                </toolbar-dropdown-nav-year>
                <currency-dropdown-menu *ngIf="showCurrencySelector"
                    [options]="getCurrencyOptions() | async"
                    [selectedOption]="getSelectedCurrency() | async"
                    [currencyId]="dashboardService.dashboardCurrencyId"
                    (valueChange)="selectCurrency($event)"
                >
                </currency-dropdown-menu>
                <!--  <toolbar-dropdown-nav [options]="getYearOptions()" [selectedOption]="getSelectedYear() | async"
                    [yearId]="dashboardService.dashboardYearId" (valueChange)="selectYear($event)"></toolbar-dropdown-nav> -->
            </div>
        </div>
        <div *ngIf="indexArray" class="col-12">
            <div class="row">
                <nav class="h-anchor-menu">
                    <ul>
                        <li
                            *ngFor="let n of indexArray"
                            [ngClass]="{
                                'index-dashboard-hidden': hiddenMenuItems.includes(n.idx)
                            }"
                            id="{{ 'anchor' + n.idx }}"
                        >
                            <span
                                [attr.title]="
                                    rightClickMenuEnabled
                                        ? 'Right click to hide/unhide section'
                                        : ''
                                "
                                (contextmenu)="onMenuRightClick(n)"
                                (click)="routeToFragment(n)"
                                class="index-dashboard"
                            >
                                {{ n.label }}
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="m-content" id="m-content">
    <ng-content></ng-content>
</div>
<!-- <div *ngIf="footer" id="slider-footer" class="slide-footer">
    <img alt="" src="./assets/app/media/img/logos/P_WHITE.svg" />
    <div class="mr-auto">
        <h3 class="m-subheader__title">
            {{ dashboardService.title }}
            <span
                [hidden]="!entityService.selectedEntityLabel"
                class="entityLabel d-none d-md-inline"
            >
                {{ entityService.selectedEntityLabel }}
            </span>
            <span
                [hidden]="!dashboardService.options_title"
                class="entity-label d-none d-xl-inline"
            >
                | {{ dashboardService.options_title }}
            </span>
        </h3>
    </div>
    <div class="swiper-pagination swiper-pagination-fraction"></div>
</div> -->

