<div class="btn-group" role="group">
    <div class="btn-group" role="group" ngbDropdown>
        <button mat-button class="btn btn-secondary btn-dropdown"
            style="border: 1px solid #7e94ac;color: #7e94ac;padding-left:10px; padding-right:10px; height: 39px;font-size: 15px;min-width: 80px;"
            ngbDropdownToggle>
            {{ selectedOption?.label }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-container">
            <div class="dropdown-search">
                <input type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchTerm" />
            </div>
            <div class="scrollable-dropdown">
                <button class="dropdown-item" *ngFor="let item of filteredOptions" (click)="select(item)">
                    {{ item.label }}
                </button>
            </div>
        </div>
    </div>
    
</div>