@use 'sass:color';
@use 'sass:map';
@use 'variables';

//TODO: merging default map with other maps that have the same key
$themes: (
    default: (
        default: (
            text: #575962,
            bg-color-lighter: white,
            bg-color: white,
            text-color-lighter: #676c7b,
            text-green: variables.$text-green-light,
            text-red: variables.$text-red-light,
            divider: #ebedf2,
            box-shadow: #999,
            date-picker-bg: variables.$white-smoke,
            table-row-border: #efefef,
            table-row-dark: #ffffff,
            table-row-highlight: #f7f2e7,
            minor-group2-row-cell-weekend: #bec9cf,
            offcanvas: #f5f5f5
        ),
        table: (
            l-c: variables.$white-smoke,
            t-c: variables.$white-smoke,
            tr-cell-bg: white,
            highlight-row: variables.$light-gray,
            cell-color: variables.$dark-gray,
            tree-level0-bg: #f8f8f8,
            tree-level0-color: variables.$app-primary,
            header-group-bg: #fff,
            th-group-bg: #fff,
            room-col-bg: color.adjust(variables.$app-primary, $lightness: 20%),
            grand-total-bg: variables.$white-smoke,
            btn-expand-all-color: variables.$dark-lighter,
            bg-header-cell: variables.$white-smoke,
            header-cell-color: variables.$app-primary,
            cell-border-color: variables.$white-smoke,
            bg-tree-level-2: #e6ebef,
            tr-even: #f9fafc,
            banded-cell: #f9fafc,
            tr-hover-bg: variables.$white-smoke,
            minor-group-cell: #bec9cf,
            tr-hover-color: #575962,
            minor-group-row-cell: #d2dee6,
            minor-group2-row-cell: #eef7fd,
            minor-group-row-hover: #bec9cf,
            minor-group2-row-hover: #dee5eb,
            minor-group3-row-hover: #e3e5e6,
            minor-group4-row-hover: rgb(189, 189, 189),
            minor-group3-row: #f4f6f7,
            light-blue: #d2dee6,
            gray-cell: lightgray,
            footer: variables.$white-smoke,
            tb-e-c: #7e94ac,
            white-cell: #575962
        ),
        header: (
            bottom-header-bg: variables.$app-primary,
            dropdown-header-bg: variables.$app-accent,
            dropdown-border-color: #bbc8d1,
            box-shadow: rgba(81, 77, 92, 0.5)
        ),
        footer: (
            bg-color: variables.$app-primary
        ),
        button: (
            bg-color: white,
            border-color: #ebedf2,
            btn-border-hover: #ebedf2,
            toggle-btn-checked: variables.$app-primary,
            btn-bg-hover: variables.$white-smoke
        ),
        sticky-filter: (
            icon-color: #98a4c4,
            box-shadow: #999,
            bg-options-filter: #ffffff
        ),
        quick-sidenav: (
            background: #ffffff,
            box-shadow: rgba(81, 77, 92, 0.2)
        ),
        anchor-menu: (
            bg-anchor-menu: variables.$app-primary,
            anchor-menu-color: #d2d9de,
            anchor-menu-bg-hover: #eee,
            color-anchor-hover: variables.$app-accent
        ),
        dashboard: (
            text-color: variables.$dark-gray,
            entity-label: variables.$app-primary,
            loading-section-bg: rgba(255, 255, 255, 0.8),
            no-data-section: rgba(255, 255, 255, 0.92)
        ),
        navigation: (
            link-color: #676c7b,
            link-item-here: variables.$app-primary,
            dropdown-item-here-color: #fff
        ),
        theme-switch: (
            icon-sun: #b1b0b5,
            icon-moon: #b1b0b5,
            switch-bg: #f9f9f9,
            switch-bg-hover: #f9f9f9,
            border-shadow: #fff,
            switch-circle-bg: variables.$app-primary,
            switch-circle-pos: 0.52rem
        ),
        entity-filter: (
            text-color: rgba(0, 0, 0, 0.871),
            entity-filter-bg: variables.$white-smoke,
            border-color: gray
        ),
        admin: (
            th-text-color: rgba(0, 0, 0, 0.54),
            filter-row-bg: #fafafa,
            icon-color: #a2a2a4,
            border-color: #e0e0e0
        ),
        mat-resets: (
            progess-bar-fill-after: variables.$white-smoke,
            progress-bar-bg: white
        ),
        loading: (
            background-color: rgba(0, 0, 0, 0.1)
        ),
        dx-pivot-table: (
            caption: rgba(0, 0, 0, 0.54),
            border-color: #e0e0e0,
            data-header-bg: rgba(221, 221, 221, 0.2),
            pop-up-bg: #fff
        )
    ),
    dark: (
        default: (
            text: #ccccdc,
            bg-color-lighter: variables.$dark-lighter,
            bg-color: variables.$black,
            text-green: variables.$text-green-dark,
            text-red: variables.$text-red-dark,
            divider: variables.$black,
            box-shadow: #333333,
            date-picker-bg: variables.$black,
            table-row-border: #1e1e2d,
            table-row-dark: #1e1e2d,
            table-row-highlight: hsl(240deg, 22%, 28%),
            minor-group2-row-cell-weekend: #364266,
            offcanvas: #2d3f52
        ),
        table: (
            l-c: color.adjust(variables.$dark-lighter, $lightness: -2%),
            t-c: color.adjust(variables.$dark-lighter, $lightness: -2%),
            tr-cell-bg: #1f1f31,
            highlight-row: #3d3d5f,
            cell-color: #b9b9bd,
            tree-level0-bg: color.adjust(variables.$black, $lightness: 3%),
            tree-level0-color: color.adjust(variables.$app-primary, $lightness: -5%),
            header-group-bg: variables.$black,
            th-group-bg: variables.$dark-lighter,
            room-col-bg: color.adjust(variables.$dark-lighter, $lightness: 5%),
            grand-total-bg: #2c2235,
            btn-expand-all-color: variables.$dark-lighter,
            bg-header-cell: color.adjust(variables.$dark-lighter, $lightness: -2%),
            header-cell-color: #b9b9bd,
            cell-border-color: #323248,
            bg-tree-level-2: hsl(240, 22%, 28%),
            tr-even: color.adjust(variables.$dark-lighter, $lightness: -5%),
            banded-cell: #3d3d5f,
            tr-hover-bg: #364266,
            minor-group-cell: #364266,
            tr-hover-color: #ccc,
            minor-group-row-cell: #3d3d5f,
            minor-group2-row-cell: #1d2539,
            minor-group-row-hover: #364266,
            minor-group2-row-hover: #364266,
            minor-group3-row-hover: #364266,
            minor-group4-row-hover: #364266,
            light-blue: rgba(59, 132, 211, 0.5),
            gray-cell: rgba(108, 108, 120, 0.5),
            footer: hsl(240, 22%, 28%),
            minor-group3-row: color.adjust(#1d2539, $lightness: -2%),
            tb-e-c: #7e94ac,
            white-cell: #ccc
        ),
        header: (
            // bottom-header-bg: color.adjust($dark-lighter, $lightness: 5%),
            bottom-header-bg: color.adjust(variables.$black, $lightness: 5%),
            dropdown-header-bg: variables.$app-accent,
            dropdown-border-color: color.adjust(#1b1b29, $lightness: 20%),
            box-shadow: #1f1f31
        ),
        footer: (
            bg-color: variables.$dark-lighter
        ),
        button: (
            bg-color: variables.$dark-lighter,
            border-color: variables.$black,
            btn-border-hover: #11111a,
            toggle-btn-checked: #3d3d5f,
            btn-bg-hover: #ccccdc29
        ),
        sticky-filter: (
            icon-color: variables.$app-accent,
            box-shadow: rgba(0, 0, 0, 0.302),
            bg-options-filter: #1f1f31
        ),
        quick-sidenav: (
            background: variables.$dark-lighter,
            box-shadow: color.adjust(variables.$black, $lightness: 5%)
        ),
        anchor-menu: (
            bg-anchor-menu: color.adjust(variables.$dark-lighter, $lightness: 5%),
            anchor-menu-color: #d9dadf,
            anchor-menu-bg-hover: #1b1b29
        ),
        dashboard: (
            text-color: #b9b9bd,
            entity-label: variables.$app-primary,
            loading-section-bg: variables.$dark-lighter,
            no-data-section: rgba(30, 30, 45, 0.92)
        ),
        navigation: (
            link-color: #b9b9bd,
            link-item-here: #1f1f31,
            dropdown-item-here-color: color.adjust(
                    variables.$dark-lighter,
                    $lightness: 5%
                )
        ),
        theme-switch: (
            icon-sun: #b1b0b5,
            icon-moon: #b1b0b5,
            switch-bg: variables.$dark-lighter,
            switch-bg-hover: #2b2b40,
            border-shadow: #000,
            switch-circle-bg: variables.$app-accent,
            switch-circle-pos: 3.15rem
        ),
        entity-filter: (
            text-color: rgb(204, 204, 220),
            entity-filter-bg: color.adjust(variables.$dark-lighter, $lightness: 5%),
            border-color: variables.$black
        ),
        admin: (
            th-text-color: #b9b9bd,
            filter-row-bg: color.adjust(variables.$dark-lighter, $lightness: 10%),
            icon-color: color.adjust(variables.$white-smoke, $lightness: -15%),
            border-color: variables.$black
        ),
        mat-resets: (
            progess-bar-fill-after: variables.$app-primary,
            progress-bar-bg: #2f3544
        ),
        loading: (
            background-color: rgb(21, 21, 33, 1)
        ),
        dx-pivot-table: (
            caption: #ccccdc,
            data-header-bg: variables.$dark-lighter,
            border-color: variables.$black,
            pop-up-bg: #1f1f31
        )
    )
);

@mixin themed($arg: 'default') {
    @each $theme, $map in $themes {
        .theme--#{$theme} & {
            $theme-map: (
                ) !global;
            $default-map: map.get($map, 'default'
            );
        $ok: map.get($map, '#{$arg}');

        @each $key, $submap in $ok {
            $value: map.get($ok, $key);
            $theme-map: map.merge($theme-map,
                    ($key: $value )) !global;
        }

        @if $arg !='default' {
            $theme-map: map.merge($theme-map, $default-map) !global;
        }

        @content;
        $theme-map: null !global;
    }
}
}

@function get-theme-color($key) {
    @return map.get($theme-map, $key);
}
