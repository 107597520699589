@use 'variables';
@use 'color-themes';

.dashboard-header {
    //height: 120px;
    padding: 40px 0 0 0;
    h3 {
        display: inline-block;
        padding: 7px 25px 7px 0;
        font-family: Roboto;
        font-weight: 300;
        font-size: 1.55rem;
        font-weight: 500;
        vertical-align: middle;
        margin: 0 15px 0 0;
        @include color-themes.themed('dashboard') {
            color: color-themes.get-theme-color('text-color');
        }
        .entity-label {
            color: variables.$app-primary;
        }
    }
    .dx-field-value {
        width: 100% !important;
    }
    .dashboard-header-daterange {
        display: inline-block;
        border-radius: 2rem;
        padding: 5px;
        cursor: pointer;
        background: #e0e8ed;
        margin-right: 10px;
        > .dashboard-header-daterange-label {
            padding: 0.7rem 0.75rem 0.7rem 1.5rem;
            font-weight: 500;
        }
        > .btn {
            width: 32px !important;
            height: 32px !important;
            i {
                font-size: 0.8rem !important;
            }
        }
    }
}
div.p-table {
    display: table;
    float: right;
    > div {
        display: table-cell;
        vertical-align: middle;
    }
}
.options-radio {
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    background-clip: content-box;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
}
.options-radio:checked {
    background-color: hsl(45, 100%, 37%);
}
#bar {
    color: white;
    background-color: #7e94ac !important;
    font-weight: 600;
}

.progress-bar {
    background-color: #7e94ac !important;
}

table {
    width: 100%;
    align-self: center;
    line-height: 1.5rem;
}

.mat-calendar-table-header th {
    // background-color: #fff !important;
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color-lighter') !important;
    }
}

h4 {
    display: inline-block;
    padding: 7px 25px 7px 0;
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.3rem;
    font-weight: 500;
    vertical-align: middle;
    margin: 3rem 1rem 1rem 1rem;
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text');
    }
}
.status {
    display: flex;
    align-items: center;
}
#data-src th {
    color: white;
}
