import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';
import {
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'currency-dropdown-menu',
    templateUrl: './currency-dropdown-menu.component.html',
    styleUrls: ['./currency-dropdown-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgbDropdown, MatButton, NgbDropdownToggle, NgbDropdownMenu, NgFor, FormsModule]
})
export class CurrencyDropdownMenuComponent {
    @Input() currencyId: number = 0;

    @Input() options: DropDownOption[] = [];

    @Input() selectedOption: DropDownOption | null | undefined;

    @Output() valueChange = new EventEmitter<DropDownOption>();

    searchTerm: string = '';

    select(selectedOption: DropDownOption) {
        this.searchTerm = '';
        this.selectedOption = selectedOption;
        this.valueChange.emit(selectedOption);
    }
    get filteredOptions() {
        // Filter options based on the search term (case-insensitive)
        return this.options.filter(option =>
            option.label.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }
}
export interface DropDownOption {
    id: string | number;
    label: string;
}
